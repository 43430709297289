import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/MinervaTools.net/MinervaTools.net/src/layout/simpleMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Imprint`}</h1>
    <Seo title={"Imprint"} description="Legal and Contact Information for MinervaTools.net" mdxType="Seo" />
    <p><em parentName="p">{`Information in compliance with Section 5 of the german TMG:`}</em></p>
    <p><strong parentName="p">{`MinervaTools`}</strong><br />{`
Eichenweg 48`}<br />{`
25451 Quickborn`}<br />{`
Germany`}</p>
    <h3>{`Represented by`}</h3>
    <p>{`Kevin Kandlbinder`}</p>
    <h3>{`Contact information`}</h3>
    <p>{`Phone: `}<a parentName="p" {...{
        "href": "tel:+4941068068005"
      }}>{`+49 4106 8068005`}</a><br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:hello@minervatools.net"
      }}>{`hello@minervatools.net`}</a><br />{`
Website: `}<a parentName="p" {...{
        "href": "https://minervatools.net"
      }}>{`https://minervatools.net`}</a></p>
    <h2>{`Disclaimer`}</h2>
    <h3>{`Responsibility for Content`}</h3>
    <p>{`We're curating the content for our web properties very carefully, but we cannot guarantee our content to allways be complete, correct and up to date.`}</p>
    <p>{`According to legislation we're responsible for the content we publish, but we do not take responsibility for informations provided by third parties.
We try our best to control third-party informations and content for mistakes and illegal activities, but we do not take any responsibility for anything that might slip through.
If you notice illegal or incorrect content, please contact us using the contact methods listed above and we will block or remove the offending content in compliance with
§8-10 of the german Telemedia Act (TMG).`}</p>
    <h3>{`Responsibility for Links`}</h3>
    <p>{`We've checked all third-party links at the time of publishing and have not noticed any illegal content. The responsibility for content offered on external sites is with
the operators of said sites. If you notice any illegal or disturbing content on an external site we're linking to, please contact us using the contact methods listed above
and we will remove the link from our site.`}</p>
    <h3>{`Copyright`}</h3>
    <p>{`All of our content is subject to German copyright. All use, redistribution, copying or modification of our content is subject to this copyright unless an exception is
granted by MinervaTools. Individual copies are only allowed for personal use and may not be used for commercial reasons, neither directly or indirectly.
If you'd like to use our content or would like to report a copyright violation, please contact us using the contact methods listed above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      